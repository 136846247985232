<template>
	<div id="questionList" class="el-content">
        <router-link to="/set/question/questionEdit">
            <a-button class="mb12" type="primary" v-has="{action:'/set/question/questionEdit'}">
				<i class="ri-add-line"></i>新增问题
			</a-button>
        </router-link>
        <a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'标题',dataIndex:'problem_title'},
            {title:'分类',dataIndex:'category.name'},
            {title:'状态',dataIndex:'problem_status',slots:{customRender:'problem_status'}},
            {title:'排序',dataIndex:'rank'},
            {title:'最后更新时间',dataIndex:'update_time'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" class="mt12">
            <template #problem_status="{record}">
                <a-tag color="#00CC66" v-if="record.problem_status ==1 ">显示</a-tag>
                <a-tag color="#999" v-if="record.problem_status ==0 ">隐藏</a-tag>
            </template>
            <template #action="{record}">
                <a-space>
					<router-link :to="{path:'/set/question/questionEdit',query:{id:record.id}}">
                    	<kd-button type="primary" 
							title="编辑" 
							icon="ri-edit-line" 
							v-has="{action:'/set/question/questionEdit'}">
						</kd-button>
					</router-link>
                    <kd-button type="danger" 
						title="删除" 
						icon="ri-delete-bin-5-line" 
						v-has="{action:'wxapp_set_question_del'}" 
						@click="deleteQuestion(record.id)">
					</kd-button>
                </a-space>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getQuestionList(info.page,e)}"
                @change="(e)=>{getQuestionList(e,info.limit)}"
            />
        </div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import questionModel from '@/api/question.js'
import common from "@/api/common.js"
export default{
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})
		getQuestionList(1,_d.info.limit)
		function getQuestionList(page,limit){
			questionModel.getQuestion(page,limit,res=>{
				_d.info.list = res.list
				_d.info.count = res.count
			})
		}
		const deleteQuestion = (id)=> common.deleteDataList(id,20,'确认删除该问题信息吗？').then(()=>{
			getQuestionList(_d.info.page,_d.info.limit)
		})
		return {
			...toRefs(_d),
			getQuestionList,
			deleteQuestion
		}
	}
}
</script>
<style>
</style>
